<template>
    <div class="row g-0">
        <div class="col-12 col-sm-7 order-1 order-sm-0 fund-banner-info">
            <div class="bg-dagray h-100 px-3 px-lg-5 py-3 py-md-5">
                <h4 class="h4 ff-r fw-5 text-white mb-3">Сергей Бекренев</h4>
                <div class="ff-r fs-l fw-5 text-white mb-5">
                    Основатель Фонда развития правовой культуры и правосознания
                </div>
                <div class="ff-r fs-m fw-4 text-white">
                    «Я вижу миссию Европейской Юридической Службы прежде всего в повышении правовой культуры и
                    правосознания граждан, в чётком следовании букве Закона и обязательном соблюдении всех прав
                    и обязанностей.Фонд развития правовой культуры и правосознания создан с целью привлечения
                    внимания к проблеме недостаточно высокой правовой грамотности населения, поиска
                    разнообразных способов её решения, коллаборации с партнёрами, с государственными органами и
                    общественными организациями, создания платформ и площадок для коммуникаций, объединения с
                    другими участниками рынка юридических услуг. Наша цель – повсеместная правовая грамотность,
                    правовое просвещение и правовая поддержка.»
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-5 order-0 order-sm-1">
            <div class="fund-banner-image"
                 :style="{ backgroundImage: 'url(' + require('@/assets/jpg/fund-banner2.jpg') + ')' }">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FundBanner"
    }
</script>

<style lang="scss" scoped>
    .fund-banner {
        &-info {
            .bg-dagray {
                position: relative;

                &:before {
                    content: '';
                    width: 135px;
                    height: 105px;
                    background: url(~@/assets/svg/fundBannerDecor.svg) no-repeat;
                    background-size: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    @include _992 {
                        width: 185px;
                        height: 145px;
                    }
                }

                & * {
                    position: relative;
                    z-index: 2;
                }
            }

            .fs-l {
                font-size: $fs-base;
                @include _768 {
                    font-size: $fs-large;
                    max-width: 470px;
                }
            }

            .fs-m {
                font-size: $fs-small;
                text-align: justify;
                letter-spacing: .1px;
                @include _768 {
                    font-size: $fs-medium;
                    line-height: 24px;
                }
            }
        }

        &-image {
            height: 350px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center top -100px;

            @include _576 {
                height: 100%;
              //background-position: center;
              background-position: center top -100px;
            }
        }
    }
</style>